import React, { useRef } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  IconButton,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styles from './settings.module.scss';
import { Camera } from 'assets/svgs';
import { Title } from 'components/common/Title';
import { Tabs } from 'components/common/Tabs';
import SettingsAnimations from 'components/common/Animations/SettingsAnimations';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROFILE } from '_redux/types/profile.types';
import { authActions } from '_redux/slices/auth.slice';
import { UPDATE_PROFILE_IMAGE } from '_redux/types/auth.types';
import { profileActions } from '_redux/slices/profile.slice';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phoneNo: Yup.string().required('Phone number is required'),
  address: Yup.string().required('Address is required'),
});

const Settings = ({ tab, tabs, handleTabChange }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authActions.selectors.getUser);
  const isProfileLoading = useSelector(
    profileActions.selectors.getProfileLoading
  );

  const isLoading = useSelector(state => state.profile.isLoading);

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phoneNo: user?.phoneNo || '',
    address: user?.address || '',
  };

  const imageInputRef = useRef(null);

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (file) {
      dispatch({
        type: UPDATE_PROFILE_IMAGE,
        payload: {
          id: user?.id,
          file,
        },
      });
    }
  };

  const handleCameraClick = () => {
    imageInputRef.current?.click();
  };

  const handleSubmit = values => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        id: user?.id,
        ...values,
      },
    });
  };

  return (
    <Box className={styles.settingsContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Title title="Profile Settings" />
      </Box>
      <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      <SettingsAnimations type="fadeInUp" duration={0.6}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            errors,
            touched,
          }) => (
            <Form className={styles.formContainer}>
              <Box className={styles.profileHeader}>
                <Box className={styles.avatarContainer}>
                  <Avatar
                    src={user?.profileImageUrl}
                    alt="Profile Picture"
                    className={styles.avatar}
                  />
                  {isProfileLoading && (
                    <Box className={styles.loaderContainer}>
                      <CircularProgress className={styles.imageLoader} />
                    </Box>
                  )}
                  <IconButton
                    className={styles.cameraIcon}
                    onClick={handleCameraClick}
                  >
                    <Camera />
                  </IconButton>
                  <input
                    type="file"
                    ref={imageInputRef}
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                </Box>
                <Typography variant="h6" className={styles.userName}>
                  {`${values.firstName} ${values.lastName}`}
                </Typography>
                <Typography variant="body2" className={styles.userId}>
                  ID #{user?.id}
                </Typography>
              </Box>

              <Grid container spacing={3} className={styles.formFields}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    variant="outlined"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="phoneNo"
                    label="Phone Number"
                    variant="outlined"
                    value={values.phoneNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phoneNo && Boolean(errors.phoneNo)}
                    helperText={touched.phoneNo && errors.phoneNo}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="address"
                    label="Address"
                    variant="outlined"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    className={styles.textField}
                  />
                </Grid>
              </Grid>

              <Box className={styles.actionButtons}>
                <Button variant="outlined" className={styles.cancelButton}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={styles.saveButton}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </SettingsAnimations>
    </Box>
  );
};

export default Settings;
