import React from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styles from './advisorInfo.module.scss';
import { UPDATE_PAYOUT } from '_redux/types/payout.types';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const validationSchema = Yup.object({
  totalPaidToFirm: Yup.number(),
  // .required('Total Paid to Firm is required')
  // .positive('Must be a positive number'),
  employeeID: Yup.string().required('Employee ID is required'),
  advisorSplit: Yup.number().required('Advisor Split / FCI is required'),
  payAways: Yup.number().required('Payment / Client Back is required'),
  advisorBalance: Yup.number().required('Advisor Balance is required'),
  loanRepayment: Yup.number().required('Loan Repayment is required'),
  deduction: Yup.number().required('Deduction Fees are required'),
  expenses: Yup.number().required('Expenses are required'),
  advances: Yup.number().required('Advance Early Payment Request is required'),
  // amountDue: Yup.number().required('Amount Due to Advisor is required'),
  datePaid: Yup.date().required('Date Paid is required'),
  amountPaid: Yup.number().required('Amount Paid is required'),
  lgShare: Yup.number().required('LG Share / Margin is required'),
});

const AdvisorInfo = ({ advisorData, handleClose, limit, skip }) => {
  const dispatch = useDispatch();

  const initialValues = {
    totalPaidToFirm: advisorData?.netPayout || 0,
    employeeID: advisorData?.advisor.advisorId || '',
    advisorSplit: advisorData?.totalAdvisorSplit || 0,
    payAways: advisorData?.advisor?.payAways || 0,
    advisorBalance: advisorData?.advisor?.advisorBalance || 0,
    loanRepayment: advisorData?.advisor?.loanRepayment || 0,
    deduction: advisorData?.advisor?.deduction || 0,
    expenses: advisorData?.advisor?.expenses || 0,
    advances: advisorData?.advisor?.advances || 0,
    // amountDue: advisorData?.amountDue || '',
    datePaid: advisorData?.advisor?.datePaid
      ? dayjs(advisorData?.advisor?.datePaid)
      : dayjs(),
    amountPaid: advisorData?.advisor?.amountPaid || 0,
    lgShare: advisorData?.advisor?.margin || 0,
  };

  const handleSubmit = values => {
    const payload = {
      data: [
        {
          id: advisorData?.advisor?.id,
          updatedFields: {
            deduction: values.deduction,
            expenses: values.expenses,
            advances: values.advances,
            loanRepayment: values.loanRepayment,
            advisorBalance: values.advisorBalance,
            amountPaid: values.amountPaid,
            datePaid: values.datePaid.toISOString(),
            payAways: values.payAways,
          },
        },
      ],
    };

    dispatch({ type: UPDATE_PAYOUT, payload, limit, skip });
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={styles.advisorInfoContainer}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Advisor Information</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    disabled
                    fullWidth
                    name="totalPaidToFirm"
                    label="Total Paid to Firm"
                    value={values.totalPaidToFirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.totalPaidToFirm && Boolean(errors.totalPaidToFirm)
                    }
                    helperText={
                      touched.totalPaidToFirm && errors.totalPaidToFirm
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    disabled
                    name="employeeID"
                    label="Advisor ID"
                    value={values.employeeID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.employeeID && Boolean(errors.employeeID)}
                    helperText={touched.employeeID && errors.employeeID}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    disabled
                    fullWidth
                    name="advisorSplit"
                    label="Advisor Split / FCI"
                    value={values.advisorSplit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.advisorSplit && Boolean(errors.advisorSplit)}
                    helperText={touched.advisorSplit && errors.advisorSplit}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="payAways"
                    label="Payment / Claw Back"
                    value={values.payAways}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.payAways && Boolean(errors.payAways)}
                    helperText={touched.payAways && errors.payAways}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="advisorBalance"
                    label="Advisor Balance"
                    value={values.advisorBalance}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.advisorBalance && Boolean(errors.advisorBalance)
                    }
                    helperText={touched.advisorBalance && errors.advisorBalance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="loanRepayment"
                    label="Loan Repayment"
                    value={values.loanRepayment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.loanRepayment && Boolean(errors.loanRepayment)
                    }
                    helperText={touched.loanRepayment && errors.loanRepayment}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="deduction"
                    label="Deduction Fees"
                    value={values.deduction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.deduction && Boolean(errors.deduction)}
                    helperText={touched.deduction && errors.deduction}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="expenses"
                    label="Expenses"
                    value={values.expenses}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.expenses && Boolean(errors.expenses)}
                    helperText={touched.expenses && errors.expenses}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="advances"
                    label="Advance Early Payment Request"
                    value={values.advances}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.advances && Boolean(errors.advances)}
                    helperText={touched.advances && errors.advances}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DatePicker
                    label="Date Paid"
                    value={values.datePaid}
                    sx={{ width: '100%' }}
                    onChange={newValue => {
                      setFieldValue('datePaid', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        error={touched.datePaid && Boolean(errors.datePaid)}
                        helperText={touched.datePaid && errors.datePaid}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="amountPaid"
                    label="Amount Paid"
                    value={values.amountPaid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amountPaid && Boolean(errors.amountPaid)}
                    helperText={touched.amountPaid && errors.amountPaid}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    name="lgShare"
                    label="LG Share / Margin = Total FCI"
                    value={values.lgShare}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lgShare && Boolean(errors.lgShare)}
                    helperText={touched.lgShare && errors.lgShare}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    color: '#003466',
                    borderColor: '#003466',
                    '&:hover': {
                      color: 'red',
                      borderColor: 'darkred',
                    },
                    mr: 1,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    backgroundColor: '#003466',
                    '&:hover': {
                      borderColor: 'darkred',
                      backgroundColor: '#003466',
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </LocalizationProvider>
  );
};

export default AdvisorInfo;
