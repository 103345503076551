import React, { useCallback, useMemo, useState } from 'react';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  Popover,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { CloudUpload, Download, Edit, Search } from '@mui/icons-material';
import styles from './genericTable.module.scss';
import Animation from 'utils/Animation';
import { card } from 'utils/styles';
import { uploadButton } from 'utils/styles';
import { icon } from 'utils/styles';
import { Title } from 'components/common/Title';
import LeftToRight from 'utils/LeftToRight';
import { Tabs } from 'components/common/Tabs';
import ColsSettings from './ColsSettings/ColsSettings';
import { ColFilterIcon } from 'assets/svgs';
import { formatDate } from 'utils';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { fetchAllCommissionsService } from '_redux/services/allData.service';
import { fetchAllErrorLogsService } from '_redux/services/allData.service';
import { fetchAllEarlyPayService } from '_redux/services/allData.service';
import { fetchAllRefundPayService } from '_redux/services/allData.service';
import { fetchAllpayoutService } from '_redux/services/allData.service';
// import { FilterIcon } from 'assets/svgs';

const GenericTable = ({
  title,
  data = [],
  columns,
  pageConfig,
  onUploadClick,
  isMain = false,
  isTabs = false,
  handleTabChange,
  tab,
  tabs = [],
  isUpload = false,
  isSearch = true,
  isEdit = false,
  isRefundRequest = false,
  isPayRequest = false,
  onEarlyPayRequest,
  onRefundRequest,
  onUpdateRows,
  period,
  setPeriod,
  isPeriod = false,
  fetchType = 'commissions',
  isDownload = true,
  isPagination = true,
  onAddExpense,
  isAddExpense = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);

  const [selectedCols, setSelectedCols] = useState(
    columns.map(col => col.field)
  );

  const [splitTypeFilters, setSplitTypeFilters] = useState({
    Advisor: false,
    SplitPartner: false,
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = row => {
    setSelectedRows(prev =>
      prev.some(selected => selected.id === row.id)
        ? prev.filter(selected => selected.id !== row.id)
        : [...prev, row]
    );
  };

  const { page, setPage, rowsPerPage, setRowsPerPage, totalRows } = pageConfig;

  const [updatedRows, setUpdatedRows] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const handlePopoverOpen = event => {
    setAnchorEl1(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl1(null);
  };

  const isPopoverOpen = Boolean(anchorEl1);

  const handleFilterChange = filter => {
    setSplitTypeFilters(prev => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleSaveClick = () => {
    const rowsToUpdate = Object.keys(updatedRows).map(rowId => ({
      id: parseInt(rowId),
      updatedFields: updatedRows[rowId],
    }));

    if (onUpdateRows && rowsToUpdate.length > 0) {
      onUpdateRows(rowsToUpdate);
      setUpdatedRows({});
    }
  };

  const handleFieldChange = useCallback((rowId, field, value) => {
    setUpdatedRows(prev => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: value !== undefined ? value : '',
      },
    }));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenColsSettings = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColsSettings = () => {
    setAnchorEl(null);
  };

  const handleToggleCol = colField => {
    setSelectedCols(prev =>
      prev.includes(colField)
        ? prev.filter(field => field !== colField)
        : [...prev, colField]
    );
  };

  const handleSearchChange = useCallback(event => {
    setSearchQuery(event.target.value);
  }, []);

  const filteredData = useMemo(
    () =>
      data
        .filter(row => {
          const splitType = row.splitType;
          if (splitTypeFilters.Advisor && splitType === 'Adviser') return true;
          if (splitTypeFilters.SplitPartner && splitType === 'Split Partner')
            return true;
          return !splitTypeFilters.Advisor && !splitTypeFilters.SplitPartner;
        })
        .filter(row =>
          columns.some(column => {
            const cellValue =
              column.field === 'date'
                ? formatDate(row[column.field])
                : row[column.field];
            return String(cellValue || '')
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          })
        ),
    [data, columns, searchQuery, splitTypeFilters]
  );

  const filteredColumns = useMemo(
    () => columns?.filter(col => selectedCols?.includes(col.field)),
    [columns, selectedCols]
  );

  const handlePeriodChange = event => {
    setPeriod(event.target.value);
  };

  const fetchAllData = async () => {
    try {
      let response;
      switch (fetchType) {
        case 'commissions':
          response = await fetchAllCommissionsService();
          response = response?.data?.CommissionSplit;
          break;
        case 'payouts':
          response = await fetchAllpayoutService({ period });
          response = response?.data?.payoutsArray;
          break;
        case 'errorLogs':
          response = await fetchAllErrorLogsService();
          response = response?.data?.ErrorLogs;
          break;
        case 'earlyPayments':
          response = await fetchAllEarlyPayService();
          response = response?.data?.EarlyPayment;
          break;
        case 'refundPayments':
          response = await fetchAllRefundPayService();
          response = response?.data?.RefundPayment;
          break;
        default:
          throw new Error('Invalid fetch type');
      }
      return response || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleExportCSV = async () => {
    const allData = await fetchAllData(); // <-- Fetch all data here
    const csvData = allData?.map(row =>
      columns.reduce((acc, column) => {
        acc[column.field] = row[column.field];
        return acc;
      }, {})
    );
    const csvRows = [
      columns.map(col => col.headerName).join(','), // Headers
      ...csvData.map(row =>
        columns.map(col => JSON.stringify(row[col.field] || '')).join(',')
      ),
    ];
    const csvBlob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.download = `${title || 'table_data'}.csv`;
    link.click();
  };

  const handleAddExpenseClick = () => {
    if (onAddExpense) {
      onAddExpense(selectedRows);
    }
  };

  return (
    <Card sx={card}>
      {isSearch ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box className={styles.buttonsBox}>
            <Title title={title} />
            <Box className={styles.buttons}>
              {isUpload ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<CloudUpload />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={onUploadClick}
                  >
                    Upload
                  </Button>
                </Animation>
              ) : null}
              {isAddExpense && selectedRows.length > 0 ? (
                <Animation>
                  <Button
                    variant="contained"
                    // startIcon={<CloudUpload />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={handleAddExpenseClick}
                  >
                    Add Expense
                  </Button>
                </Animation>
              ) : null}
              {isRefundRequest ? (
                <Animation>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#FFCE00',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#002244',
                        color: 'white',
                      },
                    }}
                    onClick={() => onRefundRequest()}
                  >
                    Refund Request
                  </Button>
                </Animation>
              ) : null}
              {isPayRequest ? (
                <Animation>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#FFCE00',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#002244',
                        color: 'white',
                      },
                    }}
                    onClick={() => onEarlyPayRequest()}
                  >
                    Early Payment Request
                  </Button>
                </Animation>
              ) : null}
              {isEdit ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={handleSaveClick}
                  >
                    Edit
                  </Button>
                </Animation>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
      {isTabs && tabs.length > 0 ? (
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      ) : null}
      <Box sx={uploadButton}>
        {isSearch ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            p={1}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E0E0E0',
                  },
                },
              }}
              sx={{ width: '100%' }}
            />
            {isPeriod ? (
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  minWidth: 120,
                  backgroundColor: '#fff',
                }}
              >
                <InputLabel id="period-select-label">Period</InputLabel>
                <Select
                  labelId="period-select-label"
                  value={period}
                  onChange={handlePeriodChange}
                  label="Period"
                  sx={{
                    borderRadius: '0.5rem',
                  }}
                >
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            ) : null}
            {isDownload ? (
              <IconButton sx={icon} onClick={handleExportCSV}>
                <Download />
              </IconButton>
            ) : null}
            <IconButton sx={icon} onClick={handleOpenColsSettings}>
              <ColFilterIcon />
            </IconButton>
          </Box>
        ) : null}
        <ColsSettings
          columns={columns}
          selectedCols={selectedCols}
          onToggleCol={handleToggleCol}
          anchorEl={anchorEl}
          handleClose={handleCloseColsSettings}
        />
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {isAddExpense ? (
                  <TableCell
                    padding="checkbox"
                    sx={{ backgroundColor: '#F4F7FC' }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < data.length
                      }
                      checked={
                        data.length > 0 && selectedRows.length === data.length
                      }
                      onChange={() => {
                        setSelectedRows(
                          selectedRows.length === data.length ? [] : data
                        );
                      }}
                    />
                  </TableCell>
                ) : null}
                {filteredColumns.map((column, index) => (
                  <TableCell
                    key={index}
                    className={styles.tableHeadCell}
                    padding={column.padding || 'normal'}
                    onClick={
                      column.headerName === 'Split Type'
                        ? handlePopoverOpen
                        : null
                    }
                  >
                    <Animation delay={index * 0.1}>
                      {column.headerName}
                      {column.headerName === 'Split Type' && (
                        <IconButton size="small" style={{ marginLeft: 4 }}>
                          <ArrowDropDownIcon />
                        </IconButton>
                      )}
                    </Animation>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: isMain
                      ? row.status === 'Pending'
                        ? '#F5D9D9'
                        : '#ffffff'
                      : index % 2 == 0
                        ? '#ffffff'
                        : '#F9FAFC',
                  }}
                >
                  {isAddExpense ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.some(
                          selected => selected.id === row.id
                        )}
                        onChange={() => handleSelectRow(row)}
                      />
                    </TableCell>
                  ) : null}
                  {filteredColumns.map((column, colIndex) => (
                    <TableCell key={colIndex} className={styles.tableBodyCell}>
                      <LeftToRight index={index}>
                        {isEdit && column.editAble ? (
                          <TextField
                            fullWidth
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                backgroundColor: 'transparent',
                                border: 'none',
                                fontSize: 'inherit',
                                color: 'inherit',
                              },
                            }}
                            value={
                              updatedRows[row.id]?.[column.field] !== undefined
                                ? updatedRows[row.id][column.field]
                                : row[column.field] || ''
                            }
                            onChange={e => {
                              const inputValue = e.target.value;
                              handleFieldChange(
                                row.id,
                                column.field,
                                inputValue
                              );
                            }}
                          />
                        ) : column.renderCell ? (
                          column.renderCell(row)
                        ) : (
                          row[column.field]
                        )}
                      </LeftToRight>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorEl1}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitTypeFilters.Advisor}
                  onChange={() => handleFilterChange('Advisor')}
                />
              }
              label="Adviser"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitTypeFilters.SplitPartner}
                  onChange={() => handleFilterChange('SplitPartner')}
                />
              }
              label="Split Partner"
            />
          </Box>
        </Popover>
        {isPagination ? (
          <TableFooter className={styles.tableFooterRow}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={columns.length}
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </Box>
    </Card>
  );
};

export default GenericTable;
