import apiInterceptor from './Interceptors/apiInterceptor';

export const getPayoutsService = async ({ limit, skip, period }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/payouts?limit=${limit}&skip=${skip}&period=${period}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const earlyPaymentService = async requestData => {
  return await apiInterceptor.post('/api/earlyPayment/request', requestData);
};

export const requestRefundPaymentService = async requestData => {
  return await apiInterceptor.post('/api/refundPayment/request', requestData);
};

export const updatePayoutService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/payouts/updatePayout?limit=10&skip=0',
      {
        data: updatedData?.data,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const addPayoutService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/payouts/appendExpenses?limit=10&skip=0',
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
