import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_ERROR_LOGS } from '_redux/types/errorLogs.types';
import { errorLogsActions } from '_redux/slices/errorLogs.slice';
import { fetchErrorLogsService } from '_redux/services/errorLogs.service';
import { UPDATE_VALIDATION } from '_redux/types/errorLogs.types';
import { updateValidationService } from '_redux/services/errorLogs.service';
// import { FETCH_FILE_DATA } from '_redux/types/files.types';
import { FETCH_VALIDATIONS } from '_redux/types/errorLogs.types';
import { fetchValidationLogsService } from '_redux/services/errorLogs.service';
// import toast from 'react-hot-toast';

export function* fetchErrorLogsSaga(action) {
  try {
    const response = yield call(fetchErrorLogsService, action.payload);
    if (response?.status == 200) {
      yield put(
        errorLogsActions.actions.setErrorLogs({
          errors: response?.data?.ErrorLogs,
          totalErrorLogs: response?.data?.count,
        })
      );
    }
  } catch (error) {
    console.log('Error fetching error logs', error);
  }
}

function* updateValidationSaga(action) {
  try {
    const response = yield call(updateValidationService, action.payload);

    if (response.status === 200) {
      yield put({
        type: FETCH_VALIDATIONS,
        payload: {
          limit: action.pagination?.limit,
          skip: action.pagination?.skip,
        },
      });
      // toast.success('validation Updated');
    }
  } catch (error) {
    console.error('Error updating file:', error);
  }
}

export function* fetchValidationsLogsSaga(action) {
  try {
    const response = yield call(fetchValidationLogsService, action.payload);
    if (response?.status == 200) {
      yield put(
        errorLogsActions.actions.setValidations({
          validations: response?.data?.validations,
          totalvalidationLogs: response?.data?.count,
        })
      );
    }
  } catch (error) {
    console.log('Error fetching error logs', error);
  }
}

export function* watchErrorLogsAsync() {
  yield takeEvery(FETCH_ERROR_LOGS, fetchErrorLogsSaga);
  yield takeEvery(UPDATE_VALIDATION, updateValidationSaga);
  yield takeEvery(FETCH_VALIDATIONS, fetchValidationsLogsSaga);
}
