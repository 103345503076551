import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchErrorLogsService = async ({ limit, skip }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/errorLogs?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const updateValidationService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      `/api/errorLogs?limit=${updatedData?.[0]?.limit}&skip=${updatedData?.[0]?.skip}`,
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const fetchValidationLogsService = async ({ limit, skip }) => {
  try {
    const response = await apiInterceptor.post(
      `/api/errorLogs/validation?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'Fetch validation logs failed'
    );
  }
};
