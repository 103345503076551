import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import styles from './marginChart.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, Typography } from '@mui/material';
import { FETCH_MARGIN_CHART } from '_redux/types/dashboard.types';
import { selectStyles } from 'utils/styles';

const MarginChart = () => {
  const dispatch = useDispatch();
  const [timePeriod, setTimePeriod] = useState('weekly');
  const advisorChartData = useSelector(
    state => state.dashboard?.advisorBase?.advisorBase
  );
  const advisorNames = useMemo(
    () => advisorChartData?.map(advisor => advisor.advisorName) || [],
    [advisorChartData]
  );
  const commissionPercentage = useMemo(
    () =>
      advisorChartData?.map(advisor =>
        Math.ceil(advisor?.commissionPercentage)
      ) || [],
    [advisorChartData]
  );
  const productPercentage = useMemo(
    () =>
      advisorChartData?.map(advisor => Math.ceil(advisor?.productPercentage)) ||
      [],
    [advisorChartData]
  );

  const series = useMemo(
    () => [
      {
        name: 'Commission Percentage',
        data: commissionPercentage,
      },
      {
        name: 'Product Percentage',
        data: productPercentage,
      },
    ],
    [commissionPercentage, productPercentage]
  );
  const colors = ['#FF4560', '#00E396'];

  const options = useMemo(
    () => ({
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
        dashArray: [10, 10],
      },
      markers: {
        size: 5,
        colors: colors,
        hover: {
          size: 7,
        },
      },
      colors: colors,
      legend: {
        show: true,
      },
      xaxis: {
        categories: advisorNames,
      },
      yaxis: {
        labels: {
          formatter: value => `${value}%`,
        },
      },
      tooltip: {
        y: {
          formatter: value => `${value}%`,
        },
        marker: {
          show: false,
          fillColors: colors,
        },
      },
    }),
    [advisorNames, colors]
  );

  const handleTimePeriodChange = event => {
    setTimePeriod(event.target.value);
    dispatch({ type: FETCH_MARGIN_CHART, payload: event.target.value });
  };

  return (
    <div id="chart" className={styles.box}>
      <div className={styles.header}>
        <Typography variant="h6" className={styles.heading}>
          Advisor Margin
        </Typography>
        <Select
          value={timePeriod}
          onChange={handleTimePeriodChange}
          size="small"
          className={styles.dropdown}
          sx={selectStyles}
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
        </Select>
      </div>
      <Chart options={options} series={series} type="line" height={450} />
    </div>
  );
};

export default MarginChart;
