import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_PAYOUTS } from '_redux/types/payout.types';
import { payoutActions } from '_redux/slices/payout.slice';
import { getPayoutsService } from '_redux/services/payout.service';
import toast from 'react-hot-toast';
import { REQUEST_EARLY_PAYMENT } from '_redux/types/payout.types';
import { earlyPaymentService } from '_redux/services/payout.service';
import { REQUEST_REFUND_PAYMENT } from '_redux/types/payout.types';
import { requestRefundPaymentService } from '_redux/services/payout.service';
import { UPDATE_PAYOUT } from '_redux/types/payout.types';
import { updatePayoutService } from '_redux/services/payout.service';
import { addPayoutService } from '_redux/services/payout.service';
import { ADD_PAYOUT } from '_redux/types/payout.types';

export function* fetchPayoutsSaga(action) {
  try {
    const response = yield call(getPayoutsService, action.payload);
    if (response?.status === 200) {
      yield put(
        payoutActions.actions.setPayouts({
          payouts: response?.data?.payoutsArray,
          total: response?.data?.count,
        })
      );
    }
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || 'Failed to fetch payouts';
    toast.error(errorMessage);
  }
}

export function* requestEarlyPaymentSaga(action) {
  try {
    const response = yield call(earlyPaymentService, action.payload);

    if (response?.status === 201) {
      toast.success('Early payment request submitted successfully');
      if (action?.onSuccess) {
        action?.onSuccess();
      }
    }
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message ||
      'Failed to submit early payment request.';
    toast.error(errorMessage);
  }
}

function* requestRefundPaymentSaga(action) {
  try {
    const response = yield call(requestRefundPaymentService, action.payload);

    if (response?.status === 201) {
      toast.success('Refund request submitted successfully!');
      if (action?.onSuccess) {
        action?.onSuccess();
      }
    }
  } catch (err) {
    const errorMessage =
      err?.response?.data?.message ||
      'Refund request failed. Please try again.';
    yield put(payoutActions.requestRefundPaymentFailure(errorMessage));
    toast.error(errorMessage);
  }
}

function* updatePayoutSaga(action) {
  try {
    const response = yield call(updatePayoutService, action.payload);

    if (response.status === 200) {
      toast.success('Payout Updated');
      yield put({
        type: FETCH_PAYOUTS,
        payload: {
          limit: action.limit,
          skip: action.skip,
          period: 'weekly',
        },
      });
    }
  } catch (error) {
    console.error('Error updating file:', error);
  }
}

function* addPayoutSaga(action) {
  try {
    const response = yield call(addPayoutService, action.payload);

    if (response.status === 200) {
      toast.success('Payout Updated');
      yield put({
        type: FETCH_PAYOUTS,
        payload: {
          limit: action.limit,
          skip: action.skip,
          period: 'weekly',
        },
      });
    }
  } catch (error) {
    console.error('Error updating file:', error);
  }
}

export function* watchPayoutsAsync() {
  yield takeEvery(FETCH_PAYOUTS, fetchPayoutsSaga);
  yield takeEvery(REQUEST_EARLY_PAYMENT, requestEarlyPaymentSaga);
  yield takeEvery(REQUEST_REFUND_PAYMENT, requestRefundPaymentSaga);
  yield takeEvery(UPDATE_PAYOUT, updatePayoutSaga);
  yield takeEvery(ADD_PAYOUT, addPayoutSaga);
}
