import React from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REQUEST_EARLY_PAYMENT } from '_redux/types/payout.types';
import styles from './userPayRequest.module.scss';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';

const validationSchema = Yup.object({
  advisorId: Yup.string().required('Advisor ID is required'),
  advisorName: Yup.string().required('Advisor Name is required'),
  totalCommission: Yup.number()
    .required('Total commission is required')
    .positive('Must be a positive number'),
  requestPaymentAmount: Yup.number()
    .required('Request payment amount is required')
    .positive('Must be a positive number'),
  reason: Yup.string()
    .required('Reason is required')
    .min(5, 'Reason must be at least 5 characters long'),
  requestDate: Yup.date().required('Request date is required'),
});

const UserPayRequest = ({ onClose, open }) => {
  const dispatch = useDispatch();

  const initialValues = {
    advisorId: '',
    advisorName: '',
    totalCommission: '',
    requestPaymentAmount: '',
    reason: '',
    requestDate: new Date().toISOString().substring(0, 10),
  };

  const handleSubmit = (values, { resetForm }) => {
    const requestData = {
      ...values,
    };

    dispatch({
      type: REQUEST_EARLY_PAYMENT,
      payload: requestData,
      onSuccess: () => {
        onClose();
        resetForm();
      },
    });
  };

  return (
    <ModalContainer open={open}>
      <Box p={3}>
        <Typography variant="h6" mb={3}>
          Early Payment Request
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="advisorId"
                    label="Advisor ID"
                    variant="outlined"
                    value={values.advisorId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.advisorId && Boolean(errors.advisorId)}
                    helperText={touched.advisorId && errors.advisorId}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="advisorName"
                    label="Advisor Name"
                    variant="outlined"
                    value={values.advisorName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.advisorName && Boolean(errors.advisorName)}
                    helperText={touched.advisorName && errors.advisorName}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="totalCommission"
                    label="Total Commission Earned"
                    variant="outlined"
                    value={values.totalCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.totalCommission && Boolean(errors.totalCommission)
                    }
                    helperText={
                      touched.totalCommission && errors.totalCommission
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="requestPaymentAmount"
                    label="Requested Early Payment Amount"
                    variant="outlined"
                    value={values.requestPaymentAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.requestPaymentAmount &&
                      Boolean(errors.requestPaymentAmount)
                    }
                    helperText={
                      touched.requestPaymentAmount &&
                      errors.requestPaymentAmount
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="reason"
                    label="Reason for Early Payment"
                    variant="outlined"
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.reason && Boolean(errors.reason)}
                    helperText={touched.reason && errors.reason}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="requestDate"
                    label="Request Date"
                    type="date"
                    variant="outlined"
                    value={values.requestDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.requestDate && Boolean(errors.requestDate)}
                    helperText={touched.requestDate && errors.requestDate}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={3} display="flex" justifyContent="end" gap={1}>
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    color: '#003466',
                    borderColor: '#003466',
                    '&:hover': {
                      color: 'red',
                      borderColor: 'darkred',
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: '#003466',
                    '&:hover': {
                      borderColor: 'darkred',
                      backgroundColor: '#003466',
                    },
                  }}
                >
                  Submit Request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalContainer>
  );
};

export default UserPayRequest;
