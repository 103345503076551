import React from 'react';
import { CardHeader, Select, MenuItem, Typography } from '@mui/material';
import { selectStyles } from 'utils/styles';
import { menuPropsStyles } from 'utils/styles';
import Animation from 'utils/Animation';
import styles from './cardHeader.module.scss';
import { calculateTotal } from 'utils';

const _CardHeader = ({ seriesData, handleChangeSeries, series }) => {
  return (
    <CardHeader
      title={
        <Animation>
          <Typography variant="h5" fontWeight="bold" className={styles.title}>
            £{calculateTotal(series)}
          </Typography>
        </Animation>
      }
      subheader={
        <Animation delay={0.3}>
          <Typography variant="body2" color="textSecondary">
            Total Commission Amount
          </Typography>
        </Animation>
      }
      action={
        <Animation>
          <Select
            value={seriesData}
            onChange={handleChangeSeries}
            size="small"
            sx={selectStyles}
            MenuProps={menuPropsStyles}
          >
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
          </Select>
        </Animation>
      }
    />
  );
};

export default _CardHeader;
