import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Box } from '@mui/material';
import styles from './salesChart.module.scss';
import { CHART_OPTIONS } from 'utils';
import CardHeader from './CardHeader/CardHeader';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_SALES_CHART } from '_redux/types/dashboard.types';
import { getWeekLabel } from 'utils';
import { getMonthName } from 'utils';

const SalesChart = () => {
  const dispatch = useDispatch();
  const [seriesData, setSeriesData] = useState('week');
  const salesChartData = useSelector(state => state.dashboard.totalSales);

  const chartCategories = useMemo(() => {
    return (
      salesChartData?.gross?.map((item, index) => {
        return seriesData === 'week'
          ? getWeekLabel(item.week || item.month, index)
          : getMonthName(item.month);
      }) || []
    );
  }, [salesChartData, seriesData]);

  const chartSeries = useMemo(
    () => salesChartData?.gross?.map(item => item?.totalGrossFCI) || [],
    [salesChartData]
  );

  useEffect(() => {
    dispatch({
      type: FETCH_SALES_CHART,
      payload: { period: seriesData },
    });
  }, [dispatch, seriesData]);

  const handleChangeSeries = useCallback(event => {
    setSeriesData(event.target.value);
  }, []);

  const updatedChartOptions = useMemo(
    () => ({
      ...CHART_OPTIONS,
      xaxis: {
        ...CHART_OPTIONS.xaxis,
        categories: chartCategories,
      },
      yaxis: {
        ...CHART_OPTIONS.yaxis,
        min: 0,
        max: Math.max(...chartSeries),
      },
    }),
    [chartCategories, chartSeries]
  );

  return (
    <Card sx={{ borderRadius: 2, boxShadow: 0 }} className={styles.card}>
      <CardHeader
        seriesData={seriesData}
        handleChangeSeries={handleChangeSeries}
        series={chartSeries}
      />
      <Box>
        <Chart
          options={updatedChartOptions}
          series={[{ name: 'Sales', data: chartSeries }]}
          type="area"
          height={308}
        />
      </Box>
    </Card>
  );
};

export default SalesChart;
