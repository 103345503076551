import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import AdvisorInfo from 'components/AdvisorInfo/AdvisorInfo.js';
import { reportColumns } from 'utils/reports';
import { useDispatch, useSelector } from 'react-redux';
import { payoutActions } from '_redux/slices/payout.slice';
import { FETCH_PAYOUTS } from '_redux/types/payout.types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ComposeMail from 'components/ComposeMail/ComposeMail';

const Tracking = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const payouts = useSelector(payoutActions.selectors.getPayouts);
  const totalPayouts = useSelector(payoutActions.selectors.getTotalPayouts);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [period, setPeriod] = useState('weekly');

  useEffect(() => {
    dispatch({
      type: FETCH_PAYOUTS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        period: period,
      },
    });
  }, [dispatch, page, rowsPerPage, period]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [pdfAttachment, setPdfAttachment] = useState(null);

  const handleViewAdvisor = advisorData => {
    setSelectedAdvisor(advisorData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalOpen1(false);
    setSelectedAdvisor(null);
    setPdfAttachment(null);
  };

  const generatePdf = row => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'pt');
    doc.setFont('poppins', 'bold');
    doc.setFontSize(18);
    doc.text(`Advisor Report`, 40, 40);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    const advisorInfo = [
      { label: 'Advisor Name', value: row?.advisorName },
      { label: 'Advisor ID', value: row?.advisorId },
      { label: 'Net Payout', value: row?.netPayout },
      { label: 'Deductions', value: row?.totalDeduction },
      { label: 'Period', value: row?.period },
    ];

    advisorInfo.forEach((info, index) => {
      const startY = 80 + index * 20;
      doc.setFont('poppins', 'bold');
      doc.text(`${info.label}:`, 40, startY);
      doc.setFont('poppins', 'normal');
      doc.text(info.value.toString(), 140, startY);
    });

    const tableStartY = 180;
    const advisorDetails = row.advisor.advisorDetails.map(detail => [
      detail.transactionID,
      detail.grossFCI.toFixed(2),
      detail.FCIRecognition.toFixed(2),
      detail.advisorSplitAmount.toFixed(2),
      detail.advisorSplitPercentage.toFixed(2),
      new Date(detail.date).toLocaleDateString(),
    ]);

    doc.autoTable({
      head: [
        [
          'Transaction ID',
          'Gross FCI',
          'FCI Recognition',
          'Advisor Split Amount',
          'Advisor Split %',
          'Date',
        ],
      ],
      body: advisorDetails,
      startY: tableStartY,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 6 },
      headStyles: { fillColor: [40, 44, 52], textColor: [255, 255, 255] },
    });

    return doc;
  };

  const handleDownload = row => {
    const doc = generatePdf(row);
    doc.save(`${row.advisorName}_Report.pdf`);
  };

  const handleShare = advisorData => {
    if (advisorData) {
      const doc = generatePdf(advisorData);
      const pdfBlob = doc.output('blob');
      const pdfFile = new File(
        [pdfBlob],
        `${advisorData.advisorName}_Report.pdf`,
        { type: 'application/pdf' }
      );
      setPdfAttachment(pdfFile);
    }
    setSelectedAdvisor(advisorData);
    setModalOpen1(true);
  };

  return (
    <>
      <GenericTable
        title="Advisor Report"
        data={payouts}
        columns={reportColumns(handleViewAdvisor, handleDownload, handleShare)}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalPayouts,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        isDownload={false}
        period={period}
        setPeriod={setPeriod}
        isPeriod={true}
      />
      <ModalContainer open={isModalOpen} onClose={handleCloseModal}>
        {selectedAdvisor && (
          <AdvisorInfo
            advisorData={selectedAdvisor}
            handleClose={handleCloseModal}
            limit={rowsPerPage}
            skip={page * rowsPerPage}
          />
        )}
      </ModalContainer>
      <ModalContainer open={isModalOpen1} onClose={handleCloseModal}>
        {selectedAdvisor && (
          <ComposeMail onClose={handleCloseModal} file={pdfAttachment} />
        )}
      </ModalContainer>
    </>
  );
};

export default Tracking;
