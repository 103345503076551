import { call, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_PROFILE } from '_redux/types/profile.types';
import { updateProfileService } from '_redux/services/profile.service';
import { profileActions } from '_redux/slices/profile.slice';
import toast from 'react-hot-toast';
import { authActions } from '_redux/slices/auth.slice';
import { UPDATE_PROFILE_IMAGE } from '_redux/types/auth.types';
import { uploadProfileImageService } from '_redux/services/profile.service';
import { GET_NOTIFICATIONS } from '_redux/types/profile.types';
import { getNotificationService } from '_redux/services/profile.service';
import { VIEW_NOTIFICATIONS } from '_redux/types/profile.types';
import { viewNotificationsService } from '_redux/services/profile.service';

export function* updateProfile(action) {
  try {
    yield put(profileActions.actions.setLoading(true));
    const response = yield call(updateProfileService, action.payload);
    if (response?.status === 200) {
      yield put(profileActions.actions.setProfile(response.data));
      yield put(authActions.actions.setProfileSlice({ user: response.data }));
      toast.success('Profile updated!');
    } else {
      yield put(profileActions.actions.setError('Failed to update profile.'));
    }
  } catch (error) {
    yield put(profileActions.actions.setError(error.message));
  } finally {
    yield put(profileActions.actions.setLoading(false));
  }
}

function* updateProfileImageSaga(action) {
  try {
    yield put(profileActions.actions.setImageLoading(true));
    const response = yield call(uploadProfileImageService, action.payload);

    if (response?.status === 200) {
      yield put(
        authActions.actions.setProfileImageSlice(
          response?.data?.profileImageUrl
        )
      );
      toast.success('Profile image updated successfully');
    }
  } catch (error) {
    toast.error('Failed to update profile image');
  } finally {
    yield put(profileActions.actions.setImageLoading(false));
  }
}

export function* fetchNotificationsSaga(action) {
  try {
    const response = yield call(getNotificationService, action.payload);
    if (response?.status === 200) {
      yield put(
        profileActions.actions.setNotifications(response.data?.notifications)
      );
    }
  } catch (error) {
    const errorMessage = error?.message || 'Failed to fetch mails';
    toast.error(errorMessage);
  }
}

export function* viewNotificationsSaga(action) {
  try {
    const response = yield call(viewNotificationsService, action.payload);
    if (response?.status === 200) {
      yield put({ type: GET_NOTIFICATIONS });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchProfileAsync() {
  yield takeEvery(UPDATE_PROFILE, updateProfile);
  yield takeEvery(UPDATE_PROFILE_IMAGE, updateProfileImageSaga);
  yield takeEvery(GET_NOTIFICATIONS, fetchNotificationsSaga);
  yield takeEvery(VIEW_NOTIFICATIONS, viewNotificationsSaga);
}
