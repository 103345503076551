import React from 'react';
import styles from './App.module.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from 'pages/signup/signup';
import Login from 'pages/login/login';
import { SideBar } from 'components/Sidebar';
import Dashboard from 'pages/dashboard/dashboard';
import { Header } from 'components/Header';
import Upload from 'pages/upload/upload';
import Errors from 'pages/errors/error';
import Commission from 'pages/commission/commission';
import EmployeePayout from 'pages/employeePayout/employeePayout';
import PaymentTracking from 'pages/paymentTracking/paymentTracking';
import Profile from 'pages/profile/profile';
import Support from 'pages/support/support';
import Mails from 'pages/mails/mails';
import PaymentRequests from 'pages/paymentRequests/PaymentRequests';
import RefundRequests from 'pages/refundRequests/RefundRequests';
import ProtectedRoute from 'components/ProtectedRoute';
import ProtectedRouteAdmin from 'components/ProtectedRouteAdmin';
import MarginDashboard from 'pages/marginDashboard/marginDashboard';
import MailsHistory from 'pages/mailsHistory/mailsHistory';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Dashboard />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/margin"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <MarginDashboard />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Upload />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload/:id"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Upload />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/error-logs"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Errors />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/commission-splits"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Commission />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/advisors-payout"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <EmployeePayout />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/advisor-report"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <PaymentTracking />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-settings"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Profile />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Support />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mail-box"
          element={
            <ProtectedRoute>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <Mails />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-request"
          element={
            <ProtectedRouteAdmin>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <PaymentRequests />
                </div>
              </div>
            </ProtectedRouteAdmin>
          }
        />
        <Route
          path="/refund-request"
          element={
            <ProtectedRouteAdmin>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <RefundRequests />
                </div>
              </div>
            </ProtectedRouteAdmin>
          }
        />
        <Route
          path="/mail-history"
          element={
            <ProtectedRouteAdmin>
              <div className={styles.appLayout}>
                <SideBar />
                <div className={styles.mainContent}>
                  <Header />
                  <MailsHistory />
                </div>
              </div>
            </ProtectedRouteAdmin>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
