import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { GET_EMAIL_BY_ID } from '_redux/types/mail.types';
import { mailsActions } from '_redux/slices/mail.slice';
import styles from './viewMail.module.scss';
import { formatDate, getTime } from 'utils';

const ViewMail = ({ onBack, isHistory = false, id }) => {
  const dispatch = useDispatch();
  const mail = useSelector(mailsActions.selectors.getMail);

  useEffect(() => {
    if (id) {
      dispatch({ type: GET_EMAIL_BY_ID, payload: id });
    }
  }, [dispatch, id]);

  const handleDownloadAttachment = () => {
    if (mail?.pdfFile) {
      const link = document.createElement('a');
      link.href = mail.pdfFile;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box className={styles.viewMailContainer}>
      {!isHistory ? (
        <IconButton onClick={onBack} className={styles.backButton}>
          <ArrowBackIcon />
        </IconButton>
      ) : null}

      <Box className={styles.header}>
        <Typography variant="h6" className={styles.subject}>
          Subject: {mail?.subject || 'Email Subject'}
        </Typography>
        <Typography variant="body2" className={styles.timestamp}>
          {`${formatDate(mail?.date)} ${getTime(mail?.date)}`}
        </Typography>
      </Box>

      <Box className={styles.recipientInfo}>
        <Avatar
          src={
            mail?.avatar ||
            'https://cdn-icons-png.flaticon.com/512/21/21104.png'
          }
          alt={mail?.receipentName || 'Recipient Avatar'}
          className={styles.avatar}
        />
        <Box>
          <Typography className={styles.recipientName}>
            {mail?.receipentName || 'Recipient Name'}
          </Typography>
          <Typography variant="body2" className={styles.recipientEmail}>
            {mail?.receipentEmail || 'email@domain.com'}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.messageContent}>
        <Typography variant="body1" className={styles.greeting}>
          Hello {mail?.receipentName || 'Recipient'},
        </Typography>
        <Typography variant="body2" className={styles.messageBody}>
          {mail?.description || 'Email content goes here...'}
        </Typography>
        <Typography variant="body2" className={styles.messageBody}>
          Looking forward and best regards,
        </Typography>
      </Box>

      {mail?.pdfFile && (
        <Box
          className={styles.attachmentSection}
          onClick={handleDownloadAttachment}
        >
          <InsertDriveFileIcon className={styles.attachmentIcon} />
          <Typography variant="body2" className={styles.attachmentName}>
            {mail?.pdfFile?.name || 'Attachment'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ViewMail;
