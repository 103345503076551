import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { UPDATE_PAYOUT } from '_redux/types/payout.types';
import styles from './expenses.module.scss';
import { ADD_PAYOUT } from '_redux/types/payout.types';

const Expenses = ({ open, onClose, selectedRows, limit, skip }) => {
  const dispatch = useDispatch();
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({ name: '', amount: '' });

  const handleAddExpense = () => {
    if (newExpense.name && newExpense.amount) {
      setExpenses([
        ...expenses,
        {
          id: selectedRows[expenses.length]?.id,
          name: newExpense.name,
          amount: parseFloat(newExpense.amount),
        },
      ]);
      setNewExpense({ name: '', amount: '' });
    }
  };

  const calculateTotal = () => {
    return expenses.reduce((acc, expense) => acc + expense.amount, 0);
  };

  const handleAddUpClick = () => {
    const payload = selectedRows.map(row => ({
      id: row.id,
      updatedFields: { expenses: calculateTotal() },
    }));
    dispatch({ type: ADD_PAYOUT, payload, limit, skip });
    onClose();
  };

  const handleUpdateClick = () => {
    const payload = selectedRows.map(row => ({
      id: row.id,
      updatedFields: { expenses: calculateTotal() },
    }));

    dispatch({ type: UPDATE_PAYOUT, payload: { data: payload }, limit, skip });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Manage Expenses
        </Typography>

        <Box display="flex" alignItems="center" gap={2} my={2}>
          <TextField
            label="Expense Name"
            value={newExpense.name}
            onChange={e =>
              setNewExpense({ ...newExpense, name: e.target.value })
            }
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextField
            label="Amount"
            value={newExpense.amount}
            onChange={e =>
              setNewExpense({ ...newExpense, amount: e.target.value })
            }
            variant="outlined"
            fullWidth
            size="small"
          />
          <Button
            onClick={handleAddExpense}
            variant="contained"
            sx={{
              minWidth: 'fit-content',
              background: '#003466',
              border: ' solid 1px #003466',
              ':hover': {
                background: 'white',
                border: 'solid 1px #003466',
                color: '#003466',
              },
            }}
          >
            Add
          </Button>
        </Box>

        <Box className={styles.expensesList}>
          {expenses.map(expense => (
            <Box
              key={expense.id}
              className={styles.expenseItem}
              display="flex"
              justifyContent="space-between"
            >
              <Typography>{expense.name}</Typography>
              <Typography className={styles.expenseAmount}>
                £{expense.amount.toFixed(2)}
              </Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box display="flex" justifyContent="space-between" my={2}>
          <Typography>Total</Typography>
          <Typography>
            £
            {selectedRows
              .reduce((acc, row) => acc + calculateTotal(), 0)
              .toFixed(2)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            minWidth: 'fit-content',
            border: ' solid 1px #003466',
            color: '#003466',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddUpClick}
          variant="contained"
          sx={{
            minWidth: 'fit-content',
            background: '#003466',
            border: ' solid 1px #003466',
            ':hover': {
              background: 'white',
              border: 'solid 1px #003466',
              color: '#003466',
            },
          }}
        >
          Add Up
        </Button>
        <Button
          onClick={handleUpdateClick}
          variant="contained"
          sx={{
            minWidth: 'fit-content',
            background: '#003466',
            border: ' solid 1px #003466',
            ':hover': {
              background: 'white',
              border: 'solid 1px #003466',
              color: '#003466',
            },
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Expenses;
