import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_FILES } from '_redux/types/files.types';
import { getFilesService } from '_redux/services/files.service';
import { filesActions } from '_redux/slices/files.slice';
import { UPLOAD_FILE } from '_redux/types/files.types';
import { uploadFileService } from '_redux/services/files.service';
import { SET_UPLOAD_PROGRESS } from '_redux/types/files.types';
import { FETCH_FILE_DATA } from '_redux/types/files.types';
import { fetchFileDataService } from '_redux/services/files.service';
import { updateFileService } from '_redux/services/files.service';
import toast from 'react-hot-toast';
import { UPDATE_FILE } from '_redux/types/files.types';

export function* getFiles(action) {
  try {
    const response = yield call(getFilesService, action.payload);
    if (response?.status === 200) {
      yield put(
        filesActions.actions.setFiles({
          files: response?.data?.upload,
          total: response.data?.count,
        })
      );
    }
  } catch (err) {
    console.log('error: ', err);
  }
}

export function* uploadFile(action) {
  const { formData, onUploadComplete, limit, skip } = action.payload;

  try {
    const onProgress = function (progress) {
      return put({ type: SET_UPLOAD_PROGRESS, payload: progress });
    };

    const response = yield call(uploadFileService, formData, onProgress);

    if (response?.status === 200) {
      yield put({ type: GET_FILES, payload: { limit, skip } });

      if (onUploadComplete) {
        onUploadComplete();
      }
      toast.success('File uploaded');
    } else {
      onUploadComplete();
      toast.error(response);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

export function* fetchFileData(action) {
  try {
    const response = yield call(fetchFileDataService, action.payload);
    if (response.status === 200) {
      yield put(filesActions.actions.setFileData(response.data));
    }
  } catch (error) {
    console.error('Error fetching file data:', error);
  }
}

function* updateFileSaga(action) {
  try {
    const response = yield call(updateFileService, action.payload);

    if (response.status === 200) {
      toast.success('File Updated');
      yield put({ type: FETCH_FILE_DATA, payload: action.id });

      // yield put(fileActions.updateFile({ updatedFiles: action.payload }));
    }
  } catch (error) {
    console.error('Error updating file:', error);
  }
}

export function* watchFilesAsync() {
  yield takeEvery(GET_FILES, getFiles);
  yield takeEvery(UPLOAD_FILE, uploadFile);
  yield takeEvery(FETCH_FILE_DATA, fetchFileData);
  yield takeEvery(UPDATE_FILE, updateFileSaga);
}
