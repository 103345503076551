import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth.slice';
import earlyPayments from './earlyPayment.slice';
import refundPayments from './refundPayment.slice';
import files from './files.slice';
import profile from './profile.slice';
import payout from './payout.slice';
import errorLogs from './errorLogs.slice';
import commissionSplit from './commissionSplit.slice';
import dashboard from './dashboard.slice';
import mails from './mail.slice';

const reducers = combineReducers({
  [auth.name]: auth.reducer,
  [earlyPayments.name]: earlyPayments.reducer,
  [refundPayments.name]: refundPayments.reducer,
  [files.name]: files.reducer,
  [profile.name]: profile.reducer,
  [payout.name]: payout.reducer,
  [errorLogs.name]: errorLogs.reducer,
  [commissionSplit.name]: commissionSplit.reducer,
  [dashboard.name]: dashboard.reducer,
  [mails.name]: mails.reducer,
});

export default reducers;
